import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { configureStore } from './redux/store'
import { HashRouter } from 'react-router-dom'

import { SoftUIControllerProvider } from 'context'

const App = React.lazy(() => import('./App'))

ReactDOM.render(
  <SoftUIControllerProvider>
    <Provider store={configureStore()}>
      <Suspense fallback={<div className="loading" />}>
        <HashRouter hashType="hashbang" basename="/something">
          <App />
        </HashRouter>
      </Suspense>
    </Provider>
  </SoftUIControllerProvider>,
  document.getElementById('root'),
)

// Soft UI Context Provider
