const initial_state = localStorage.user ? JSON.parse(localStorage.user) : null;

const reducer = (state = initial_state, {type, payload}) => {
  switch (type) {
    case 'LOGIN':
      return {...payload};
    case 'LOGOUT':
      return {...payload};
    default:
      return {...state};
  }
};

export default reducer;
